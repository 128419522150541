.alert {
  margin-bottom: 0;
  font-size: 14px;
  line-height: normal;
  color: #cc3d3d;
  &-warning {
    border-color: #571d1d;
    background-color: #3b1717;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}
