@import "../../styles/media-mixin";
@import "../../styles/themify";

.navigation {
  z-index: 1;
  display: none;
  flex: 0 0 auto;
  padding: 8px 25px;
  @include themify() {
    background-color: getThemeVariable("navigationBgColor");
    color: getThemeVariable("navigationLinksColor");
  }
  @include media(M) {
    display: flex;
    justify-content: space-around;
  }
  @include media(L) {
    justify-content: center;
  }
  &__link:not(:first-child) {
    @include media(L) {
      margin-left: 80px;
    }
  }
}
