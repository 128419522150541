@import "../../../../styles/media-mixin";

.event-view-slider {
  justify-content: center;
  margin-right: -15px;
  margin-bottom: 30px;
  margin-left: -15px;
  @include media(M) {
    .slick-slide:last-of-type .content-divider.vertical:last-of-type {
      margin-right: 1px;
    }
  }
}
