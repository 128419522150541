:root {
  --color-status-pending: #ff8a00;
  --color-status-finished: #201600;
}

.theme-dark {
  --color-status-pending: #ffbf19;
  --color-status-finished: #999;
}

.status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.active {
    color: var(--color-success);
  }
  &.pending {
    color: var(--color-status-pending);
  }
  &.finished {
    color: var(--color-status-finished);
  }
  &__icon {
    display: inline-block;
    margin-right: 7px;
    font-size: 25px;
  }
}
