@import "../../styles/media-mixin";
@import "../../styles/themify";

.back-to-top {
  position: fixed;
  bottom: 45px;
  left: 15px;
  z-index: 1;
  transform: translateX(-250%);
  width: 44px;
  height: 44px;
  border-width: 4px;
  border-style: solid;
  border-radius: 10px;
  font-size: 18px;
  line-height: normal;
  will-change: transform;
  transition: all 0.175s linear;
  @include themify() {
    border-color: getThemeVariable("backToTopBorderColor");
    background-color: getThemeVariable("backToTopBgColor");
    color: getThemeVariable("backToTopColor");
  }
  @include media(M) {
    bottom: 100px;
    left: 25px;
  }
  @include media(XL) {
    left: 50px;
  }
  &:hover {
    border-color: var(--color-accent);
    color: var(--color-accent);
  }
  &.isVisible {
    transform: translateX(0);
  }
}
