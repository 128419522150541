.success-block {
  padding-top: 10px;
  text-align: center;
  color: var(--color-success);
  &__icon {
    font-size: 75px;
  }
  &__text {
    margin-top: 30px;
    margin-bottom: 55px;
  }
}
