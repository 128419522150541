@import "../../styles/media-mixin";
@import "../../styles/themify";

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 15px 50px;
  text-align: center;
  @include media(M) {
    padding-top: 30px;
  }
  &__image {
    width: 190px;
    height: auto;
    margin: 20px auto 10px;
    @include media(M) {
      width: auto;
      margin-top: 25px;
      margin-bottom: 30px;
    }
  }
  &__text {
    margin-bottom: 50px;
    font-family: Neucha, cursive;
    font-size: 30px;
    line-height: normal;
    @include themify() {
      color: getThemeVariable("notFoundViewTextColor");
    }
    @include media(M) {
      font-size: 52px;
    }
    span {
      color: #cd3334;
    }
  }
  &__support {
    margin-bottom: 40px;
  }
}
