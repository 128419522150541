@import "../../styles/media-mixin";
@import "../../styles/themify";

.game-item {
  position: relative;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  @include media(S) {
    min-height: 140px;
  }
  &__action {
    display: none;
    width: 100%;
    height: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    @include media(MOBILE) {
      min-height: auto;
      font-size: 13px;
      line-height: normal;
    }
  }
  &__label {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    display: inline-block;
    min-width: 90px;
    height: 23px;
    padding-right: 8px;
    padding-left: 8px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 13px;
    color: #fff;
    &.new {
      background-color: #06f;
    }
    &.jackpot {
      background-color: #ff4f4f;
    }
    &.gameof {
      background-color: #00a31a;
    }
  }
  &__jackpot {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: linear-gradient(180deg, rgba(23, 23, 23, 0) 0%, #000 61.6%);
    text-align: center;
    font-size: 21px;
    color: #ff4f4f;
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px 5px;
    border: 2px solid var(--color-accent);
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(255, 0, 0, 0.25);
    @include themify() {
      background-color: getThemeVariable("gameItemOverlayBg");
    }
    @include media(S) {
      padding: 15px 12px 10px;
    }
    &.open {
      display: flex;
    }
  }
  &__name {
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    @include themify() {
      color: getThemeVariable("gameItemGameNameColor");
    }
    @include media(M) {
      font-size: 16px;
    }
  }
  &__favorite {
    display: none;
    @include themify() {
      color: getThemeVariable("gameItemBtnFavColor");
    }
    &.active {
      color: var(--color-accent);
      @include themify() {
        text-shadow: getThemeVariable("customShadow");
      }
    }
  }
  &__overlay.open &__action,
  &__overlay.open &__favorite {
    display: inline-block;
  }
  &:hover &__action,
  &:hover &__favorite {
    @include media(L) {
      display: inline-block;
    }
  }
  &:hover &__overlay {
    @include media(L) {
      display: flex;
    }
  }
}
