@import "../../styles/media-mixin";
@import "../../styles/themify";

.footer {
  flex: 0 0 auto;
  padding: 20px 25px;
  @include themify() {
    background-color: getThemeVariable("footerBgColor");
  }
  @include media(M) {
    padding-bottom: 80px;
  }
  &__sprites {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  &__content {
    margin-top: 30px;
    text-align: center;
    font-size: 13px;
    color: #686868;
  }
}
