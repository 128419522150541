@import "../../../styles/media-mixin";

.footer-nav {
  flex-direction: column;
  margin-bottom: 25px;
  text-align: center;
  color: #696969;
  @include media(L) {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 50px;
  }
  &__item {
    margin-bottom: 12px;
    line-height: normal;
    @include media(L) {
      margin-bottom: 0;
    }
  }
  &__item:not(:first-child) {
    @include media(L) {
      margin-left: 45px;
    }
  }
}
