@import "../../styles/media-mixin";

.main-layout {
  position: relative;
  flex: 1 0 auto;
  min-height: 250px;
  @include media(M) {
    min-height: 400px;
  }
  &__container {
    position: relative;
    min-height: 400px;
  }
}
