@use "sass:math";

@import "../../styles/themify";

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

.short-preloader {
  margin-top: 40px;
  text-align: center;
  &__wrapper {
    width: 100%;
    text-align: center;
  }
  &__dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: red;
    vertical-align: top;
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation: pulse 1s infinite calc(1s / 5 * #{$i});
      }
    }
  }
  &.custom {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 0;
  }
  &.link {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 2;
    margin-top: 0;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 0.25rem;
    @include themify() {
      background-color: getThemeVariable("loadingButtonBg");
    }
  }
  &.link &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
  }
  &.link &__dot {
    flex: 0 0 10px;
    vertical-align: unset;
  }
  &.button {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    z-index: 2;
    margin-top: 0;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 0.25rem;
    @include themify() {
      background-color: getThemeVariable("loadingButtonBg");
    }
  }
  &.button &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
  }
  &.button &__dot {
    flex: 0 0 10px;
    vertical-align: unset;
  }
}
