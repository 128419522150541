@import "../../styles/media-mixin";

.event-view {
  color: var(--color-primary);
  &__status {
    justify-content: center;
    margin-bottom: 15px;
    @include media(M) {
      justify-content: flex-end;
    }
  }
  &__name {
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 700;
    @include media(M) {
      margin-bottom: 20px;
      font-size: 30px;
    }
  }
  &__prize-label,
  &__prize-value {
    color: var(--color-accent);
  }
  &__prize-label {
    text-align: center;
  }
  &__prize-value {
    margin-bottom: 10px;
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    line-height: normal;
    @include media(M) {
      font-size: 60px;
    }
  }
  &__stats {
    justify-content: center;
    margin-bottom: 30px;
  }
}
