@import "../../../../styles/media-mixin";

.event-stats-item {
  width: 100%;
  padding: 12px 5px;
  text-align: center;
  line-height: normal;
  @include media(M) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  &__label {
    color: var(--color-accent);
  }
}
