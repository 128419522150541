@import "../../styles/themify";

.sprite {
  display: block;
  background-image: url("/sprite.svg?v1");
  background-repeat: no-repeat;
  &-qiwi {
    width: 90px;
    height: 40px;
    background-position: -6px -7px;
  }
  &-yamoney {
    width: 76px;
    height: 23px;
    @include themify() {
      background-position: getThemeVariable("spriteYamoneyPosition");
    }
  }
  &-playson {
    width: 112px;
    height: 24px;
    @include themify() {
      background-position: getThemeVariable("spritePlaysonPosition");
    }
  }
  &-amaticdirect {
    width: 86px;
    height: 27px;
    background-position: -238px -198px;
  }
  &-betsoft {
    width: 74px;
    height: 29px;
    background-position: -740px -58px;
  }
  &-endorphina {
    width: 83px;
    height: 38px;
    background-position: -251px -139px;
  }
  &-hab {
    width: 113px;
    height: 23px;
    @include themify() {
      background-position: getThemeVariable("spriteHabaneroPosition");
    }
  }
  &-qs {
    width: 104px;
    height: 25px;
    background-position: -433px -57px;
  }
  &-microgaming {
    width: 104px;
    height: 26px;
    background-position: -541px -57px;
  }
  &-netent {
    width: 89px;
    height: 32px;
    background-position: -649px -57px;
  }
  &-18plus {
    width: 67px;
    height: 64px;
    @include themify() {
      background-position: getThemeVariable("sprite18plusPosition");
    }
  }
  &-curacao {
    width: 68px;
    height: 62px;
    background-position: -448px -129px;
  }
  &-visa_mastercard {
    width: 123px;
    height: 28px;
    background-position: -6px -51px;
  }
  &-perfectmoney,
  &-perfect_money {
    width: 116px;
    height: 24px;
    @include themify() {
      background-position: getThemeVariable("spritePerfectMoneyPosition");
    }
  }
  &-payeer {
    width: 104px;
    height: 24px;
    background-position: -702px -12px;
  }
  &-beeline {
    width: 111px;
    height: 36px;
    @include themify() {
      background-position: getThemeVariable("spriteBeelinePosition");
    }
  }
  &-tele2 {
    width: 103px;
    height: 38px;
    @include themify() {
      background-position: getThemeVariable("spriteTele2Position");
    }
  }
  &-megafon {
    width: 123px;
    height: 23px;
    background-position: -327px -9px;
  }
  &-mts {
    width: 86px;
    height: 41px;
    background-position: -573px -315px;
  }
  &-skrill {
    width: 80px;
    height: 26px;
    background-position: -617px -12px;
  }
  &-neteller {
    width: 116px;
    height: 20px;
    background-position: -490px -16px;
  }
  &-sofort {
    width: 104px;
    height: 31px;
    background-position: -129px -141px;
  }
  &-piastrix,
  &-piastrix_wallet {
    width: 104px;
    height: 42px;
    background-position: -7px -166px;
  }
  &-b2crypto,
  &-b2binpay {
    width: 160px;
    height: 23px;
    background-position: -436px -96px;
  }
  &-jeton_wallet {
    width: 116px;
    height: 32px;
    background-position: -288px -315px;
  }
  &-ecopayz {
    width: 99px;
    height: 24px;
    background-position: -621px -93px;
  }
  &-mir {
    width: 106px;
    height: 30px;
    background-position: -621px -118px;
  }
  &-rapidtransfer {
    width: 114px;
    height: 34px;
    @include themify() {
      background-position: getThemeVariable("rapidTransferPosition");
    }
  }
  &-paysafecard {
    width: 110px;
    height: 19px;
    background-position: -114px -283px;
  }
  &-nordea {
    width: 106px;
    height: 23px;
    background-position: -7px -283px;
  }
  &-cap_bank,
  &-cep_bank {
    width: 85px;
    height: 30px;
    @include themify() {
      background-position: getThemeVariable("capBankPosition");
    }
  }
  &-qr {
    width: 97px;
    height: 29px;
    @include themify() {
      background-position: getThemeVariable("qrBankPosition");
    }
  }
  &-community_bank {
    width: 75px;
    height: 30px;
    @include themify() {
      background-position: getThemeVariable("communityBankPosition");
    }
  }
  &-paygiga {
    width: 111px;
    height: 35px;
    @include themify() {
      background-position: getThemeVariable("paygigaBankPosition");
    }
  }
  &-easyeft {
    width: 112px;
    height: 27px;
    background-position: -238px -226px;
  }
  &-astropay {
    width: 110px;
    height: 41px;
    @include themify() {
      background-position: getThemeVariable("astropayBankPosition");
    }
  }
  &-webmoney {
    width: 99px;
    height: 25px;
    @include themify() {
      background-position: getThemeVariable("webmoneyPosition");
    }
  }
  &-zimpler {
    width: 102px;
    height: 28px;
    background-position: -2px -311px;
  }
  &-mastercard {
    width: 62px;
    height: 37px;
    background-position: -478px -315px;
  }
  &-papara {
    width: 99px;
    height: 28px;
    background-position: -113px -315px;
  }
  &-neosurf {
    width: 95px;
    height: 40px;
    background-position: -192px -352px;
  }
  &-visa {
    width: 72px;
    height: 24px;
    background-position: -405px -315px;
  }
  &-astropay_card {
    width: 112px;
    height: 42px;
    @include themify() {
      background-position: getThemeVariable("astropayCardPosition");
    }
  }
  &-btc {
    width: 143px;
    height: 30px;
    background-position: -571px -363px;
  }
  &-tether {
    width: 141px;
    height: 45px;
    background-position: -426px -354px;
  }
  &-eth {
    width: 141px;
    height: 35px;
    background-position: -509px -405px;
  }
  &-poli {
    width: 103px;
    height: 36px;
    background-position: -4px -352px;
    @include themify() {
      background-position: getThemeVariable("poliPosition");
    }
  }
  &-macropay {
    width: 122px;
    height: 50px;
    @include themify() {
      background-position: getThemeVariable("macropayPosition");
    }
  }
  &-payid {
    width: 85px;
    height: 40px;
    @include themify() {
      background-position: getThemeVariable("payIdPosition");
    }
  }
  &-bpay {
    width: 86px;
    height: 40px;
    background-position: -192px -435px;
  }
  &-advcash {
    width: 160px;
    height: 30px;
    @include themify() {
      background-position: getThemeVariable("advcashPosition");
    }
  }
  &-sticpay {
    width: 160px;
    height: 33px;
    background-position: -464px -445px;
  }
  &-ezeewallet {
    width: 140px;
    height: 30px;
    @include themify() {
      background-position: getThemeVariable("eZeeWalletPosition");
    }
  }
  &-mifinity {
    width: 160px;
    height: 28px;
    @include themify() {
      background-position: getThemeVariable("miFinityPosition");
    }
  }
  &-flexepin {
    width: 160px;
    height: 46px;
    @include themify() {
      background-position: getThemeVariable("flexepinPosition");
    }
  }
  &-muchbetter {
    width: 160px;
    height: 36px;
    @include themify() {
      background-position: getThemeVariable("muchBetterPosition");
    }
  }
  &-banktransfer_xp {
    width: 120px;
    height: 60px;
    background-position: -697px -503px;
  }
  &-sofort_alt {
    width: 140px;
    height: 70px;
    background-position: -670px -408px;
  }
  &-banktransfer_alt {
    width: 120px;
    height: 60px;
    @include themify() {
      background-position: getThemeVariable("bankTransferAlt");
    }
  }
  &-volt {
    width: 120px;
    height: 75px;
    background-position: -270px -580px;
  }
  &-gcb {
    width: 150px;
    height: 85px;
    background-position: -408px -583px;
  }
}
