@import "../../styles/themify";

.pin {
  position: relative;
  z-index: 1;
  display: inline-block;
  @include themify() {
    color: getThemeVariable("pinColor");
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--color-accent);
  }
  &.sm {
    min-width: 20px;
    font-size: 12px;
  }
  &.md {
    min-width: 30px;
  }
  &.sm::before {
    width: 20px;
    height: 20px;
  }
  &.md::before {
    width: 30px;
    height: 30px;
  }
}
