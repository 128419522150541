$media-icons-config: (
  vk: #0075c9,
  tw: #00d1ff,
  fa: #217aff,
  go: #dc4e41,
  od: #f90,
  te: #00d1ff,
);

.media-icons {
  display: flex;
  align-items: center;
  color: var(--color-secondary);
  &__label {
    margin-right: 7px;
    font-weight: 700;
  }
  &__link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-top: 2px;
    margin-bottom: 2px;
    border: 1px solid var(--color-secondary);
    border-radius: 50%;
    font-size: 22px;
    &:not(:last-child) {
      margin-right: 6px;
    }
    @each $icon, $color in $media-icons-config {
      &.#{$icon}:not(.active):hover {
        border-color: $color;
        color: $color;
      }
    }
    &.active {
      pointer-events: none;
      border-color: var(--color-accent);
      color: var(--color-accent);
    }
  }
  &.vertical {
    flex-wrap: wrap;
    justify-content: center;
  }
  &.vertical &__label {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 6px;
    text-align: center;
  }
}
