@import "../../styles/media-mixin";
@import "../../styles/themify";

.post-registration {
  padding-top: 0;
  padding-bottom: 10px;
  @include themify() {
    background-image: getThemeVariable("postRegModalBg");
  }
  &__info {
    margin-right: auto;
    margin-left: auto;
    padding-top: 15px;
    text-align: center;
  }
  &__title {
    margin-bottom: 7px;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    @include themify() {
      color: getThemeVariable("postRegModalMainColor");
    }
  }
  &__description {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 20px;
    @include themify() {
      color: getThemeVariable("postRegModalMainColor");
    }
  }
  &__image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 253px;
    max-width: 100%;
    height: 253px;
    margin: 5px auto;
  }
  &__button {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &__link {
    display: inline-block;
    margin-top: 7px;
    text-decoration: underline;
    color: $color-warning;
    &:hover {
      color: #ffa030;
    }
  }
  &__dots {
    margin: 10px 0 0;
    padding: 0;
    text-align: center;
    li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-left: 10px;
      border: 1px solid #666;
      border-radius: 50%;
      vertical-align: middle;
      @include media(M) {
        margin-right: 20px;
        margin-left: 20px;
      }
      &.slick-active {
        border-color: $color-warning;
        background-color: $color-warning;
      }
      button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 0;
      }
    }
  }
  &__warning {
    margin-top: 8px;
    font-size: 17px;
    color: $color-danger;
  }
}
