@import "./media-mixin";
@import "./themify";

.modal-dialog {
  @include media(M) {
    max-width: 620px;
  }
}

.modal-backdrop {
  min-height: 100vh;
  @include themify() {
    background-color: getThemeVariable("modalBackdropColor");
  }
  &.show {
    @include themify() {
      opacity: getThemeVariable("modalBackdropOpacity");
    }
  }
}

.modal-content {
  border-radius: 4px;
  @include themify() {
    border: 1px solid getThemeVariable("modalContentBorderColor");
    background: getThemeVariable("modalContentBgColor");
  }
}

.modal-header {
  position: relative;
  justify-content: center;
  padding-right: 45px;
  padding-left: 45px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  @include themify() {
    border-bottom: getThemeVariable("modalHeaderBorder");
    background-color: getThemeVariable("modalHeaderBgColor");
  }
  .modal-title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: var(--color-accent);
  }
}

.modal-close {
  position: absolute;
  top: 22px;
  right: 15px;
  z-index: 1;
  font-size: 18px;
  @include themify() {
    color: getThemeVariable("modalCloseBtnColor");
  }
  &:hover {
    @include themify() {
      color: getThemeVariable("modalCloseBtnHoverColor");
    }
  }
}

.modal-body {
  padding: 20px 0 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
