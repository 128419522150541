@import "../../../styles/themify";

.field-action {
  padding: 6px 12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  line-height: normal;
  user-select: none;
  @include themify() {
    border: 1px solid getThemeVariable("inputButtonBorderColor");
    background-color: getThemeVariable("inputButtonBgColor");
    color: getThemeVariable("inputButtonColor");
  }
  &.small {
    font-size: 12px;
  }
  &:disabled {
    @include themify() {
      background-color: getThemeVariable("inputButtonDisabledBgColor");
      color: getThemeVariable("inputButtonDisabledColor");
    }
  }
}
