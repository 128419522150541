@font-face {
  font-family: Arsenal;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local("Arsenal Regular"),
    local("Arsenal-Regular"),
    url("/fonts/arsenal-v5-latin_cyrillic-ext-regular.woff2") format("woff2"),
    url("/fonts/arsenal-v5-latin_cyrillic-ext-regular.woff") format("woff");
}
@font-face {
  font-family: Arsenal;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local("Arsenal Bold"),
    local("Arsenal-Bold"),
    url("/fonts/arsenal-v5-latin_cyrillic-ext-700.woff2") format("woff2"),
    url("/fonts/arsenal-v5-latin_cyrillic-ext-700.woff") format("woff");
}
@font-face {
  font-family: Neucha;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local("Neucha"),
    url("/fonts/neucha-v12-latin_cyrillic-regular.woff2") format("woff2"),
    url("/fonts/neucha-v12-latin_cyrillic-regular.woff") format("woff");
}
@font-face {
  font-family: "LXGW";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local(LXGWWenKaiMono),
    url("/fonts/LXGWWenKaiMonoTC-Regular.woff2") format("woff2");
}
