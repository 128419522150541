@import "../../../styles/media-mixin";
@import "../../../styles/themify";

@keyframes ring {
  0% { transform: rotate(0) scale(1.18); }
  1% { transform: rotate(30deg) scale(1.18); }
  3% { transform: rotate(-28deg) scale(1.18); }
  5% { transform: rotate(34deg) scale(1.18); }
  7% { transform: rotate(-32deg) scale(1.18); }
  9% { transform: rotate(30deg) scale(1.18); }
  11% { transform: rotate(-28deg) scale(1.18); }
  13% { transform: rotate(26deg) scale(1.18); }
  15% { transform: rotate(-24deg) scale(1.18); }
  17% { transform: rotate(22deg) scale(1.18); }
  19% { transform: rotate(-20deg) scale(1.18); }
  21% { transform: rotate(18deg) scale(1.18); }
  23% { transform: rotate(-16deg) scale(1.18); }
  25% { transform: rotate(14deg) scale(1.18); }
  27% { transform: rotate(-12deg) scale(1.18); }
  29% { transform: rotate(10deg) scale(1.18); }
  31% { transform: rotate(-8deg) scale(1.18); }
  33% { transform: rotate(6deg) scale(1.18); }
  35% { transform: rotate(-4deg) scale(1.18); }
  37% { transform: rotate(2deg) scale(1.18); }
  39% { transform: rotate(-1deg) scale(1.18); }
  41% { transform: rotate(1deg) scale(1.18); }
  43% { transform: rotate(0) scale(1); }
  100% { transform: rotate(0); }
}

.header {
  display: block;
  flex: 0 0 auto;
  padding: 15px 0;
  @include themify() {
    background-color: getThemeVariable("headerBgColor");
  }
  @include media(M) {
    padding-top: 7px;
    padding-bottom: 10px;
  }
  > .container {
    padding-right: 10px;
    padding-left: 10px;
    @include media(S) {
      padding-right: 0;
      padding-left: 0;
    }
  }
  &__logo {
    flex: 1 0 auto;
    text-align: center;
    @include media(M) {
      flex-basis: 210px;
      flex-grow: 0;
      text-align: left;
    }
    &-image {
      width: 170px;
      @include media(M) {
        display: block;
        width: 100%;
      }
    }
  }
  &__language {
    width: 40px;
    text-align: center;
    @include media(M) {
      order: 4;
      width: auto;
    }
    &.logged {
      display: block;
      @include media(M) {
        display: none;
      }
    }
  }
}

.guest-header {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin-top: 20px;
  @include media(M) {
    flex: 0 0 auto;
    margin-top: 0;
    margin-right: 35px;
    margin-left: auto;
  }
  @include media(XL) {
    margin-right: 25px;
  }
  &__sign-up {
    margin-right: 10px;
    padding: 9px 10px;
    @media screen and (max-width: 767px) {
      flex: 3 0 auto;
    }
  }
  &__sign-in {
    padding: 7px 10px;
    @media screen and (max-width: 767px) {
      flex: 1 0 auto;
    }
    @include media(M) {
      margin-right: 25px;
      padding-right: 25px;
      padding-left: 25px;
    }
    @include media(XL) {
      margin-right: 50px;
    }
  }
  &__social {
    margin-right: 25px;
  }
}

.logged-header {
  flex: 1 0 auto;
  align-items: center;
  font-weight: 700;
  color: var(--color-secondary);
  &__username {
    display: inline-block;
    margin-right: 20px;
    margin-left: 20px;
    @include media(XL) {
      margin-right: 32px;
      margin-left: 32px;
    }
    &:hover {
      color: #ccc;
    }
  }
  &__balance {
    display: inline-block;
    @include media(S) {
      &:hover {
        color: #ccc;
      }
    }
  }
  &__icon {
    position: relative;
    top: 4px;
    display: inline-block;
    font-size: 25px;
    @include media(M) {
      margin-right: 5px;
    }
  }
  &__props {
    display: inline-block;
    max-width: 80px;
    margin-left: 4px;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
    overflow: hidden;
    @include themify() {
      color: getThemeVariable("loggedHeaderPropsColor");
    }
  }
  &__active-bonus {
    margin-left: 20px;
    @include media(XL) {
      margin-left: 40px;
    }
  }
  &__cashbox {
    margin-right: 8px;
    margin-left: auto;
    padding: 8px 26px;
  }
  &__presents {
    margin-right: 20px;
    padding: 7px 14px;
    @include media(XL) {
      margin-right: 35px;
    }
  }
  &__logout {
    &.is-disabled {
      pointer-events: none;
    }
    &:not(.is-disabled):hover {
      @include themify() {
        color: getThemeVariable("loggedHeaderLogoutHover");
      }
    }
  }
  &__bell {
    position: absolute;
    top: -10px;
    right: -10px;
    line-height: 1;
    text-shadow: none;
    will-change: transform;
    animation: ring 3s 0.7s linear infinite;
  }
  &__theme-switcher {
    margin-right: 10px;
  }
}

.logged-header-actions {
  margin-top: 15px;
  font-weight: 700;
  &__item {
    padding-top: 3px;
    padding-bottom: 6px;
    text-align: center;
    @include themify() {
      background-color: getThemeVariable("loggedHeaderActionsBg");
      color: getThemeVariable("loggedHeaderActionsColor");
    }
    &:not(:first-child) {
      border-left: 1px solid #000;
    }
  }
  &__active-bonus {
    display: flex;
    justify-content: center;
  }
  &__presents {
    position: relative;
    margin-right: 10px;
  }
  &__cashbox {
    margin-top: 12px;
    &-wrapper {
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-left: 10px;
      border-radius: 8px;
      @include themify() {
        border: 1px solid getThemeVariable("loggedHeaderCashboxWrapperBorder");
        box-shadow: getThemeVariable("customShadow");
      }
    }
    &-balance {
      margin-right: auto;
      padding-right: 15px;
      padding-left: 15px;
      @include themify() {
        color: getThemeVariable("loggedHeaderActionsColor");
      }
    }
    &-btn {
      padding: 9px 30px;
      border: 0;
      border-radius: 0 4px 4px 0;
    }
  }
}
