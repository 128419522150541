@import "../../styles/media-mixin";

.divider {
  display: block;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  &::-moz-selection {
    background: transparent;
  }
  &::selection {
    background: transparent;
  }
  &.colored {
    height: 98px;
  }
  &.black {
    height: 30px;
    background-size: cover;
    @include media(M) {
      height: 72px;
    }
  }
  &.grey {
    height: 30px;
    background-size: cover;
    @include media(M) {
      height: 72px;
    }
  }
  &.light {
    height: 30px;
    background-size: cover;
    @include media(M) {
      height: 60px;
    }
    @include media(L) {
      height: 98px;
    }
  }
}
