.game-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  &__loader {
    z-index: 0;
    max-width: 80px;
    margin-right: auto;
    margin-left: auto;
    background-color: unset !important;
  }
}
