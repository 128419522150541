@import "../../styles/media-mixin";
@import "../../styles/themify";

.responsive-table-cell {
  @include themify() {
    color: getThemeVariable("responsiveTableCellColor");
  }
  @include media(MOBILE) {
    display: flex;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  @include media(M) {
    padding: 12px;
    white-space: nowrap;
  }
  &__label {
    flex: 0 1 200px;
    margin-right: 12px;
    @include themify() {
      color: getThemeVariable("responsiveTableCellLabelColor");
    }
  }
}
