@import "../../styles/media-mixin";

.game-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 18px;
  @include media(MOBILE) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  &__action {
    margin-top: 20px;
    text-align: center;
  }
  &__button {
    padding: 12px 35px;
  }
  &__preloader {
    padding-top: 10px;
  }
}
