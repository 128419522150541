@import "../../styles/media-mixin";

.content-divider {
  @include media(M) {
    &.vertical {
      flex-shrink: 0;
      align-self: stretch;
      width: 1px;
      height: auto;
      background: var(--gradient-vertical-divider);
    }
  }
  &.horizontal {
    width: 100%;
    height: 1px;
    background: var(--gradient-horizontal-divider);
  }
}
