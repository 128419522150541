@import "../../styles/media-mixin";

.nothing-found-block {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
  font-size: 20px;
  color: #999;
  @include media(M) {
    font-size: 30px;
  }
}
