@import "../../styles/variables";

.error-block {
  padding-top: 10px;
  text-align: center;
  color: $color-danger;
  &__icon {
    font-size: 75px;
  }
  &__text {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}
