@import "../../styles/media-mixin";
@import "../../styles/themify";

.custom-page {
  width: 100%;
  max-width: 620px;
  margin-right: auto;
  margin-left: auto;
  padding: 15px 15px 20px;
  text-align: center;
  @include media(M) {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  &__title {
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: Neucha, cursive;
    font-size: 28px;
    @include media(M) {
      margin-top: 35px;
      margin-bottom: 35px;
      font-size: 36px;
    }
    @include themify() {
      color: getThemeVariable("maintenanceHeaderColor");
    }
  }
  &__description {
    @include themify() {
      color: getThemeVariable("maintenanceTextColor");
    }
  }
  &__content {
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &__button {
    width: 100%;
    margin-top: 20px;
  }
  &__logo {
    cursor: default;
  }
}
