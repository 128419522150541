@import "../../styles/themify";
@import "../../styles/media-mixin";

.slider-arrows {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  display: inline-block;
  @include themify() {
    background-color: getThemeVariable("sliderArrowsBgColor");
  }
  &:hover {
    background-color: rgba(68, 68, 68, 0.6);
  }
  &__content {
    display: inline-block;
    padding-top: 23px;
    padding-bottom: 23px;
    font-size: 34px;
    color: #fff;
  }
  &--left {
    left: 0;
    border-top-right-radius: 180px;
    border-bottom-right-radius: 180px;
  }
  &--left &__content {
    padding-right: 7px;
  }
  &--right {
    right: 0;
    border-top-left-radius: 180px;
    border-bottom-left-radius: 180px;
  }
  &--right &__content {
    padding-left: 7px;
  }
  &-outline {
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    font-size: 46px;
    line-height: normal;
    color: #373737;
    transition: color 0.2s ease-in-out;
    @include media(S) {
      &:hover {
        color: #999;
      }
    }
    &--left {
      left: 0;
      @include media(S) {
        left: -45px;
      }
    }
    &--right {
      right: 0;
      @include media(S) {
        right: -45px;
      }
    }
  }
}
