$color-warning: #ffbf19;
$color-danger: #ff3b3b;
$color-primary: #00c2ff;
$color-darkolivegreen: #5e481c;
$custom-text-shadow: 0 0 4px $color-danger;
$theme-light-color-warning: #cd3334;
$theme-light-color-default: #000;

$input-placeholder-col: #4d4d4d;

$XS: 320px;
$S: 576px;
$M: 768px;
$L: 992px;
$XL: 1200px;
