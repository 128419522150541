$languages: "pt", "de", "en", "fi", "no", "pl", "ru", "es", "tr";

.language-flag {
  display: inline-block;
  width: 18px;
  height: 17px;
  background-image: url("/img/flags.svg");
  background-repeat: no-repeat;
  background-position-y: 0;
  @each $lang in $languages {
    $i: index($languages, $lang);

    &.#{$lang} {
      background-position-x: -18px * ($i - 1);
    }
  }
}
