@import "../../styles/media-mixin";
@import "../../styles/themify";

.responsive-table-row {
  @include media(MOBILE) {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    line-height: normal;
    @include themify() {
      color: getThemeVariable("responsiveTableRowColor");
    }
    &:not(:last-child) {
      @include themify() {
        border-bottom: 2px solid getThemeVariable("responsiveTableRowBorder");
      }
    }
  }
}
