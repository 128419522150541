@import "../../styles/themify";

$buttons-config: (
  primary: (
    background: "buttonPrimaryBg",
    hoverBackgroundVariable: "buttonPrimaryHoverBg",
    color: "buttonPrimaryColor",
  ),
  outlined: (
    borderVariable: "buttonOutlinedBorderColor",
    hoverBorderColor: "buttonOutlinedHoverBorderColor",
    hoverBackgroundVariable: "buttonOutlinedHoverBgColor",
    textShadowVariable: "customShadow",
    color: "buttonOutlinedColor",
    hoverColorVariable: "buttonOutlinedHoverColor",
  ),
);

.button {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  min-height: 44px;
  padding: 6px 12px;
  border-radius: 4px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  &.disabled,
  &:disabled {
    opacity: 0.8;
  }
  &-danger {
    background: linear-gradient(180deg, #ff002e, #ff5954 51.28%, #ff3d00);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    color: #fff;
    &:active,
    &:hover {
      background: #fc6535 linear-gradient(180deg, #ff4f6f, rgba(255, 255, 255, 0));
      color: #fff;
    }
  }
  &-block {
    display: block;
    width: 100%;
  }
}

@each $button, $color in $buttons-config {
  .button-#{$button} {
    @include themify() {
      background: getThemeVariable(map-get($color, background));
      text-shadow: getThemeVariable(map-get($color, textShadowVariable));
      color: getThemeVariable(map-get($color, color));
      @if map-has-key($color, borderVariable) {
        border: 2px solid getThemeVariable(map-get($color, borderVariable));
      }
    }
    &:active,
    &:hover {
      @include themify() {
        border-color: getThemeVariable(map-get($color, hoverBorderColor));
        background: getThemeVariable(map-get($color, hoverBackgroundVariable));
        color: getThemeVariable(map-get($color, hoverColorVariable or color));
      }
    }
  }
}
