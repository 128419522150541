@import "../../styles/themify";

.radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  @include themify() {
    color: getThemeVariable("radioColor");
  }
  &__group {
    display: flex;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  &__dot {
    position: relative;
    flex: 0 0 auto;
    margin-right: 6px;
    border-radius: 50%;
    @include themify() {
      border: 1px solid getThemeVariable("radioIconBorderColor");
      background-color: getThemeVariable("radioIconBgColor");
    }
    .sm & {
      width: 16px;
      height: 16px;
    }
    .lg & {
      width: 24px;
      height: 24px;
    }
  }
  &__dot::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    @include themify() {
      border-color: getThemeVariable("radioIconBgColor");
      background: getThemeVariable("radioIconActiveBg");
    }
  }
  input[type=radio] {
    display: none;
  }
  &__label {
    flex: 1;
    .lg & {
      font-size: 18px;
    }
  }
  &:hover &__label,
  &.active &__label,
  input[type=radio]:checked ~ &__label {
    color: var(--color-accent);
    @include themify() {
      text-shadow: getThemeVariable("customShadow");
    }
  }
  &:hover &__dot::before,
  &.active &__dot::before,
  input[type=radio]:checked ~ &__dot::before {
    content: "";
  }
}
