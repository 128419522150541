.timer {
  text-align: center;
  text-transform: uppercase;
  color: var(--color-secondary);
  &__label {
    margin-bottom: 3px;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
  }
  &__title {
    margin-bottom: -3px;
    font-size: 15px;
    span {
      display: inline-block;
      width: 29px;
    }
  }
  &__content {
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
  }
}
