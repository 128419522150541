@import "./variables";

@function translate-media-condition($c) {
  $condMap: (
    "XS": "(min-width: #{$XS})",
    "S": "(min-width: #{$S})",
    "M": "(min-width: #{$M})",
    "L": "(min-width: #{$L})",
    "XL": "(min-width: #{$XL})",
    "MOBILE": "(max-width: #{$M - 1px})",
    "TABLET": "(max-width: #{$L - 1px})",
    "DESKTOP": "(max-width: #{$XL - 1px})",
  );
  $result: map-get($condMap, $c);
  @if ($result == null) {
    $result: $c;
  }
  @return $result;
}

@mixin media($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ($query != "") {
      $op: " and ";
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}
