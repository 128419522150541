@import "../../styles/media-mixin";

.tab-link {
  position: relative;
  display: block;
  padding: 5px 10px;
  text-align: center;
  color: #999;
  &::before,
  &:last-child::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;
    background: var(--gradient-vertical-divider);
    @include media(M) {
      width: 1px;
      height: 100%;
      background: var(--gradient-vertical-divider);
    }
  }
  &::before {
    left: 0;
  }
  &:last-child::after {
    right: 0;
    @include media(MOBILE) {
      top: auto;
      bottom: 0;
    }
  }
}
