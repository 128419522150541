@import "../../styles/media-mixin";
@import "../../styles/themify";

.responsive-table {
  margin-bottom: 0;
  @include media(MOBILE) {
    display: block;
  }
  @include media(M) {
    width: 100%;
    overflow-x: auto;
  }
  &__content {
    width: 100%;
    max-width: 100%;
    @include media(M) {
      border-collapse: collapse;
    }
  }
  tbody,
  tr {
    @include media(MOBILE) {
      display: block;
    }
  }
  th {
    padding: 15px 12px;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
    @include themify() {
      color: getThemeVariable("responsiveTableHeadingColor");
    }
  }
  tbody tr:nth-child(odd) {
    @include media(M) {
      @include themify() {
        background: getThemeVariable("responsiveTableTrOddBg");
      }
    }
  }
  tbody tr:nth-child(even) {
    @include media(M) {
      @include themify() {
        background: getThemeVariable("responsiveTableTrEvendBg");
      }
    }
  }
}
