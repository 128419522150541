@import "./media-mixin";

:root {
  --color-accent: #cd3334;
  --color-secondary: #000;
  --color-success: #7bce28;
  --color-primary: #000;
  --gradient-vertical-divider: linear-gradient(rgba(153, 153, 153, 0), #999 50%, rgba(153, 153, 153, 0));
  --gradient-horizontal-divider: linear-gradient(270deg, rgba(196, 196, 196, 0), #c4c4c4 48.58%, rgba(196, 196, 196, 0));
}

.theme-dark {
  --color-accent: #ffbf19;
  --color-secondary: #999;
  --color-success: #98ff30;
  --color-primary: #fff;
  --gradient-vertical-divider: linear-gradient(#0f0f0f, #ffbf19 50%, #0f0f0f);
  --gradient-horizontal-divider: linear-gradient(270deg, rgba(15, 15, 15, 0), #ffbf19 49.44%, rgba(15, 15, 15, 0));
}

* {
  min-width: 0;
  min-height: 0;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  font-family: Arsenal, sans-serif;
  &.theme-dark {
    background-color: #0f0f0f;
  }
  &.modal-open {
    position: fixed;
    width: 100%;
    @include media(M) {
      position: relative;
    }
  }
}

#root {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;
}

button {
  padding: 0;
  border: 0;
  background: none;
  &::-moz-focus-inner {
    border: 0;
  }
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

a {
  color: inherit;
  &::-moz-focus-inner {
    border: 0;
  }
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &:focus {
    outline: none;
  }
}

ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.container {
  padding-right: 10px;
  padding-left: 10px;
  @include media(M) {
    max-width: 758px;
  }
  @include media(L) {
    max-width: 992px;
  }
  @include media(XL) {
    max-width: 1200px;
    padding-right: 0;
    padding-left: 0;
  }
}

.maintenance__highlighted,
.highlighted,
.is-highlighted {
  color: var(--color-accent);
}

.dropdown-item {
  padding: 0;
  background: none !important;
  &:focus {
    outline: none;
  }
  &:active {
    color: inherit;
  }
}
