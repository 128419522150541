.theme-switcher {
  position: relative;
  padding: 11px 16px 10px;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  text-transform: lowercase;
  font-family: Neucha, cursive;
  font-size: 14px;
  line-height: normal;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 6px;
    border-style: solid;
    border-radius: 20px;
  }
  &.light {
    border-color: #0f0f0f;
    background-color: #0f0f0f;
    color: #fff;
    &::after {
      border-color: #fff;
    }
  }
  &.dark {
    border-color: #fff;
    background-color: #fff;
    color: #0f0f0f;
    &::after {
      border-color: #0f0f0f;
    }
  }
}
