@import "../../styles/themify";

.preloader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include themify() {
    background-color: getThemeVariable("preloaderBgClor");
  }
  &__dots {
    margin-top: 12px;
  }
}
