.info-modal {
  .modal-body {
    padding-bottom: 40px;
  }
  &__actions {
    text-align: center;
  }
  &__button {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &__sub-button {
    margin-top: 10px;
    text-decoration: underline;
    color: #999;
  }
}
