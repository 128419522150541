@import "./theme-definition";

$theme-map: null;

@mixin themify() {
  @each $theme, $map in $themes {
    $theme-map: map-get($themes, $theme) !global;
    .theme-#{$theme} & {
      @content;
    }
  }
}

@function getThemeVariable($key) {
  @return map-get($theme-map, $key);
}
