@import "../../../styles/variables";
@import "../../../styles/themify";

.checkbox {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  padding-left: 35px;
  text-align: left;
  cursor: pointer;
  user-select: none;
  color: var(--color-secondary);
  &__icon {
    position: absolute;
    top: 3px;
    left: 0;
    width: 25px;
    height: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    font-size: 13px;
    color: transparent;
    @include themify() {
      border: 1px solid getThemeVariable("checkboxIconBorderColor");
    }
  }
  .has-danger &__icon {
    border-color: $color-danger;
    box-shadow: 0 0 4px $color-danger, inset 0 0 4px $color-danger;
  }
  input:checked ~ .checkbox__icon {
    background: linear-gradient(180deg, #faff00 0%, rgba(255, 255, 255, 0) 100%), #ff8a00;
    color: #242424;
  }
  input {
    position: absolute;
    opacity: 0;
  }
}
