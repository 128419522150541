@import "../../styles/media-mixin";

.custom-row {
  margin-right: -4px;
  margin-left: -4px;
  @include media(M) {
    margin-right: -10px;
    margin-left: -10px;
  }
  > * {
    padding-right: 4px;
    padding-left: 4px;
    @include media(M) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
