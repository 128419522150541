@import "../../styles/themify";

.support-button {
  &__icon {
    position: relative;
    display: block;
    margin-bottom: 10px;
    font-size: 23px;
    line-height: 40px;
    color: #696969;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 43px;
      height: 43px;
      border: 4px solid #666;
      border-radius: 10px;
    }
  }
  &__label {
    font-size: 20px;
    color: #696969;
    &:hover {
      @include themify() {
        color: getThemeVariable("supportButtonHoverColor");
      }
    }
  }
}
