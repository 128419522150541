@import "../../../styles/media-mixin";
@import "../../../styles/themify";

.form-group {
  position: relative;
  margin-bottom: 10px;
  @include media(M) {
    margin-bottom: 20px;
  }
}

.form-control {
  height: 40px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 4px;
  box-shadow: none;
  font-size: 18px;
  @include themify() {
    border-color: getThemeVariable("inputBorderColor");
    background-color: getThemeVariable("inputBgColor");
    color: getThemeVariable("inputColor");
  }
  @include media(M) {
    font-size: 16px;
  }
  &:focus {
    @include themify() {
      border-color: getThemeVariable("inputFocusedBorderColor");
      background-color: getThemeVariable("inputBgColor");
      box-shadow: getThemeVariable("inputFocusedBoxShadow");
      color: getThemeVariable("inputColor");
    }
  }
  &:disabled {
    @include themify() {
      border-color: getThemeVariable("inputDisabledBorderColor");
      background-color: getThemeVariable("inputDisabledBgColor") !important;
      color: getThemeVariable("inputDisabledColor");
    }
  }
  &.valid:not(.no-icons),
  &.completed {
    @include themify() {
      background: getThemeVariable("inputValidBg");
    }
  }
  &:not(:focus).has-danger {
    @include themify() {
      border-color: getThemeVariable("inputHasDangerBorderColor");
      background: getThemeVariable("inputBgColor") url("/img/value-invalid.svg") no-repeat calc(100% - 7px) 10px;
      box-shadow: getThemeVariable("inputHasDangerBoxShadow");
    }
    &.no-icons {
      background: none;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: none !important;
  @include themify() {
    background-color: getThemeVariable("inputAutoFillBgColor") !important;
    -webkit-box-shadow: 0 0 0 30px inset getThemeVariable("inputAutoFillShadow") !important;
    color: getThemeVariable("inputAutoFillColor") !important;
    -webkit-text-fill-color: getThemeVariable("inputAutoFillColor");
  }
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
