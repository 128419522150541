@import "../../../styles/media-mixin";

#betSlip {
  /* stylelint-disable */
  #footer_coupon_div,
  #footer_bet_history {
    border-radius: 5px !important;
    background-color: $color-primary;
    color: #fff !important;
  }
}

#egamings_container {
  background-color: #25272e;
}

.sportsbook-live-view iframe {
  @include media(L) {
    max-height: 700px;
  }
}

