@import "../../styles/themify";

.navigation-link {
  font-size: 20px;
  &:focus,
  &:hover,
  &.active {
    color: var(--color-accent);
    @include themify() {
      text-shadow: getThemeVariable("customShadow");
    }
  }
  &__icon {
    margin-right: 5px;
  }
  &.has-icon {
    display: flex;
    align-items: center;
  }
}
