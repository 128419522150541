@import "../../styles/themify";

.button-back {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  @include themify() {
    color: getThemeVariable("buttonBackColor");
  }
  &:hover {
    @include themify() {
      color: getThemeVariable("buttonBackHoverColor");
    }
  }
  &__icon {
    position: relative;
    top: 2px;
    margin-right: 10px;
  }
}
