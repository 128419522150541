@import "../../styles/media-mixin";
@import "../../styles/themify";

.language-menu {
  &__toggle {
    display: block;
    font-size: 15px;
    line-height: 1;
    color: var(--color-secondary);
    @include media(M) {
      font-size: 12px;
    }
  }
  &__menu {
    width: 85px;
    min-width: 0;
    margin-top: 20px;
    padding: 5px 9px 6px;
    border-radius: 4px;
    border-color: #363636;
    background-color: #0f0f0f;
    @include media(M) {
      width: 78px;
    }
  }
  &__item {
    padding: 0;
    &:active,
    &:focus,
    &:hover {
      background-color: transparent;
    }
    &:focus {
      outline: none;
    }
    &:nth-child(n+2) {
      margin-top: 5px;
    }
  }
  &__flag {
    position: relative;
    top: 1px;
    margin-right: 7px;
  }
  &__link {
    display: inline-block;
    text-transform: uppercase;
    font-size: 20px;
    line-height: normal;
    @include themify() {
      color: getThemeVariable("languageDropdownLinkColor");
    }
    &::-moz-selection,
    &::selection {
      background: transparent;
    }
    &:hover {
      color: #999;
    }
  }
}
