@import "./simplebar.min.css";

.simplebar-scrollbar {
  &.simplebar-visible::before {
    opacity: 1;
  }
  &::before {
    background-color: var(--color-accent);
  }
}

.simplebar-horizontal {
  display: none;
}
