@import "../../styles/themify";
@import "../../styles/variables";

div.custom-select-field {
  &__indicators {
    cursor: pointer;
  }
  &__indicator-separator {
    align-self: stretch;
    width: 1px;
    margin-top: 8px;
    margin-bottom: 8px;
    @include themify() {
      background-color: getThemeVariable("customSelectInputDefaultColor");
    }
    .filter-view & {
      @include themify() {
        background-color: getThemeVariable("customSelectFilterDefaultColor");
      }
    }
  }
  &__indicator {
    padding-right: 10px;
    padding-left: 10px;
  }
  &__arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-width: 8px 8px 1px;
    border-style: solid;
    border-color: #1f1f1f transparent transparent;
    transform-origin: 50% 50%;
    transition: all 0.2s ease;
    @include themify() {
      border-top-color: getThemeVariable("customSelectInputDefaultColor");
    }
    .filter-view & {
      @include themify() {
        border-top-color: getThemeVariable("customSelectFilterDefaultColor");
      }
    }
    &.rotated {
      transform: rotate(180deg);
    }
  }
  &__single-value {
    @include themify() {
      color: getThemeVariable("inputColor");
    }
  }
  &__value-container {
    padding-left: 12px;
  }
  &__menu {
    margin-top: -9px;
    margin-bottom: 0;
    padding-top: 4px;
    border-width: 0 1px 1px;
    border-style: solid;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
    text-align: left;
  }
  &__menu::before {
    content: "";
    display: block;
    width: 100%;
    height: 5px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    @include themify() {
      border-bottom-color: getThemeVariable("customSelectInputDefaultColor");
    }
    .filter-view & {
      @include themify() {
        border-bottom-color: getThemeVariable("customSelectFilterDefaultColor");
      }
    }
  }
  &__control {
    height: 40px;
    box-shadow: none;
  }
  &__control,
  &__menu {
    @include themify() {
      border-color: getThemeVariable("inputBorderColor");
      background-color: getThemeVariable("inputBgColor");
    }
  }
  &__option {
    padding: 8px 10px;
    cursor: pointer;
  }
  &__default-option {
    color: var(--color-secondary);
  }
  &__option--is-selected,
  &__default-option:hover {
    @include themify() {
      color: getThemeVariable("customSelectMenuOptionSelectedColor");
    }
  }
  &__input {
    @include themify() {
      color: getThemeVariable("inputColor");
    }
    input {
      width: 100% !important;
    }
  }
  &__custom-placeholder {
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    color: $input-placeholder-col;
  }
  &__placeholder {
    color: var(--color-secondary);
  }
  &__custom-placeholder,
  &__single-value,
  &__placeholder,
  &__option,
  &__input {
    .filter-view & {
      font-size: 20px;
    }
  }
  &.filter-view &__option {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  &--is-disabled &__control {
    @include themify() {
      border-color: getThemeVariable("inputDisabledBorderColor");
      background-color: getThemeVariable("inputDisabledBgColor");
    }
  }
  &--is-disabled &__single-value {
    @include themify() {
      color: getThemeVariable("inputDisabledColor");
    }
  }
  &--is-disabled &__indicator-separator,
  &--is-disabled &__arrow {
    opacity: 0.35;
  }
  &.has-danger &__control {
    @include themify() {
      border-color: getThemeVariable("inputHasDangerBorderColor");
      box-shadow: getThemeVariable("inputHasDangerBoxShadow");
    }
  }
  &.filter-view &__control {
    height: 46px;
  }
  &.filter-view &__control,
  &.filter-view &__menu {
    @include themify() {
      border-color: getThemeVariable("customSelectFilterDefaultColor");
      background-color: getThemeVariable("customSelectFilterBgColor");
    }
  }
  &.searchable &__control--menu-is-open &__single-value {
    display: none;
  }
}
