@font-face {
  font-display: swap;
  font-family: "redbox";
  font-style: normal;
  font-weight: normal;
  src: url("/redbox.woff?v4") format("woff");
}

[class^="icon-"],
[class*=" icon-"] {
  text-transform: none;
  font-family: "redbox" !important;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-variant: normal;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-spin::before {
  content: "\e942";
}

.icon-recommended::before {
  content: "\e93f";
}

.icon-recent::before {
  content: "\e940";
}

.icon-virtual_sport::before {
  content: "\e93d";
}

.icon-mini_games::before {
  content: "\e93e";
}

.icon-profile-warning::before {
  content: "\e900";
}

.icon-fullscreen-off::before {
  content: "\e901";
}

.icon-fullscreen::before {
  content: "\e902";
}

.icon-auth-te::before {
  content: "\e903";
}

.icon-bell::before {
  content: "\e904";
}

.icon-auth-in::before {
  content: "\e905";
}

.icon-auth-go::before {
  content: "\e906";
}

.icon-slider-arrow-right::before {
  content: "\e907";
}

.icon-slider-arrow-left::before {
  content: "\e908";
}

.icon-all::before {
  content: "\e909";
}

.icon-error::before {
  content: "\e90a";
}

.icon-lottery-cup::before {
  content: "\e90b";
}

.icon-tournament-cup::before {
  content: "\e90c";
}

.icon-close::before {
  content: "\e90d";
}

.icon-popular::before {
  content: "\e90e";
}

.icon-slots::before {
  content: "\e90f";
}

.icon-table::before {
  content: "\e910";
}

.icon-live_casino::before {
  content: "\e911";
}

.icon-jackpots::before {
  content: "\e912";
}

.icon-checked::before {
  content: "\e913";
}

.icon-favorite::before {
  content: "\e914";
}

.icon-arrow-left::before {
  content: "\e915";
}

.icon-auth-od::before {
  content: "\e916";
}

.icon-auth-tw::before {
  content: "\e917";
}

.icon-auth-fa::before {
  content: "\e918";
}

.icon-auth-vk::before {
  content: "\e919";
}

.icon-select-arrow::before {
  content: "\e91a";
}

.icon-winners-cup::before {
  content: "\e91b";
}

.icon-user::before {
  content: "\e91c";
}

.icon-currency-rub::before {
  content: "\e91d";
}

.icon-logout::before {
  content: "\e91e";
}

.icon-gift::before {
  content: "\e91f";
}

.icon-success::before {
  content: "\e920";
}

.icon-search::before {
  content: "\e921";
}

.icon-arrow-right-circle::before {
  content: "\e922";
}

.icon-minus-circle::before {
  content: "\e923";
}

.icon-arrow-up::before {
  content: "\e924";
}

.icon-question::before {
  content: "\e925";
}

.icon-attention::before {
  content: "\e926";
}

.icon-currency-usd::before {
  content: "\e927";
}

.icon-currency-eur::before {
  content: "\e928";
}

.icon-angle-left::before {
  content: "\e929";
}

.icon-angle-right::before {
  content: "\e92a";
}

.icon-times-circle::before {
  content: "\e92b";
}

.icon-refresh::before {
  content: "\e92c";
}

.icon-currency-pln::before {
  content: "\e92d";
}

.icon-currency-try::before {
  content: "\e92e";
}

.icon-clock-circle::before {
  content: "\e92f";
}

.icon-close-circle::before {
  content: "\e930";
}

.icon-check::before {
  content: "\e931";
}

.icon-currency-pen::before {
  content: "\e932";
}

.icon-currency-zar::before {
  content: "\e933";
}

.icon-currency-brl::before {
  content: "\e934";
}

.icon-currency-nok::before {
  content: "\e935";
}

.icon-currency-cad::before {
  content: "\e936";
}

.icon-currency-aud::before {
  content: "\e937";
}

.icon-currency-chf::before {
  content: "\e938";
}

.icon-currency-czk::before {
  content: "\e939";
}

.icon-slider-angle-left::before {
  content: "\e93a";
}

.icon-slider-angle-right::before {
  content: "\e93b";
}

.icon-new_games::before {
  content: "\e93c";
}

.icon-paw::before {
  content: "\e941";
}

.icon-tournament::before {
  content: "\e943";
}

.icon-currency-kzt::before {
  content: "\eaea";
}

.icon-currency-ars::before {
  content: "\e927";
}

.icon-currency-mxn::before {
  content: "\e927";
}

.icon-currency-clp::before {
  content: "\e927";
}

.icon-crashgames::before {
  content: "\e944";
}

.icon-dropsandwins::before {
  content: "\e945";
}
