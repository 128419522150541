.veliplay-game-view > iframe {
  height: calc(100vh - 185px);
  border: 0;
}

@media (min-width: 1024px) {
  .veliplay-game-view > iframe {
    min-height: 700px;
  }
}
@media (min-width: 1280px) {
  .veliplay-game-view > iframe {
    min-height: 620px;
  }
}
